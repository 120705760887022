<template>
  <div class="page-wrapper">
    <MoleculePageHeader :title="$title">
      <div
        v-if="isStaff"
        class="col-auto ms-auto d-print-none"
      >
        <div class="btn-list">
          <AtomButton
            to="/article/create"
            color="primary"
            class="d-none d-sm-inline-block"
          >
            <plus-icon />{{ $t('article.create') }}
          </AtomButton>
          <AtomButton
            to="/article/create"
            color="primary"
            class="d-sm-none"
            :aria-label="$t('article.create')"
          >
            <plus-icon />
          </AtomButton>
        </div>
      </div>
    </MoleculePageHeader>
    <div class="page-body">
      <div class="container-xl">
        <div
          v-if="loading"
          class="row row-cards"
        >
          <div
            v-for="n in 4"
            :key="n"
            class="col-12 col-sm-6"
          >
            <MoleculeCard
              :loading="true"
              loading-type="placeholder"
            />
          </div>
        </div>
        <div
          v-else-if="articles.length"
          class="row row-cards"
        >
          <div
            v-for="article in articles"
            :key="article.id"
            class="col-12 col-sm-6"
          >
            <MoleculeCard
              :to="'/article/' + article.id"
              :image="article.image"
              :class="{'card-unpublished': !article.published, 'h-100': true }"
            >
              <h3 class="card-title">{{ article.title | capitalize }}</h3>
              <div
                v-if="article.publishedAt"
                class="card-subtitle"
              >
                <calendar-icon class="me-1" />{{ article.publishedAt | date }}
              </div>
              <p v-html="article.introtext" />
            </MoleculeCard>
          </div>
        </div>
        <MoleculeEmpty
          v-else
          :message="$t(`category.list.${$route.meta.categoryKey}.error.empty`)"
        >
          <AtomButton
            to="/"
            color="primary"
          >
            <chevron-left-icon />{{ $t('action.back_home') }}
          </AtomButton>
        </MoleculeEmpty>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import MoleculePageHeader from '@/components/MoleculePageHeader'
import MoleculeCard from '@/components/MoleculeCard'
import MoleculeEmpty from '@/components/MoleculeEmpty'
import AtomButton from '@/components/AtomButton'

export default {
  components: {
    MoleculePageHeader,
    MoleculeCard,
    MoleculeEmpty,
    AtomButton,
  },

  data: () => ({
    loading: true,
  }),

  computed: mapGetters(['articles', 'isStaff']),

  watch: {
    '$route.meta.categoryKey': {
      async handler (value) {
        this.loading = true
        try {
          this.$title = this.$t(`pages.${value}.title`)
          await this.$store.dispatch('fetchArticles', value)
        } catch {} finally {
          this.loading = false
        }
      },
      immediate: true,
    },
  },
}
</script>
